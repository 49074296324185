import React from 'react';
import './Footer.css';

export default function Footer() {
	return (
		<div className='footer-container'>
			<div>Footer will go here</div>
		</div>
	);
}
